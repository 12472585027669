// redux/ticketsSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode"; // Importing jwt-decode for token expiry check
import axiosInstance from "./axiosInstance";

// Token expiry check function
const checkTokenExpiry = (token) => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Time in seconds
  return decodedToken.exp > currentTime; // true if token is still valid
};

// Create an async thunk for fetching tickets
export const fetchTickets = createAsyncThunk(
  "tickets/fetchTickets",
  async () => {
      // #update : use axiosInstance instead of fetch :

    // const response = await axios.get("http://localhost:3001/tickets/");
    const response = await axiosInstance.get("/tickets")
    return response.data;
  }
);

// Create an async thunk for generating (creating) a ticket
// export const createTicket = createAsyncThunk(
//   "tickets/createTicket",
//   async (ticketData, { rejectWithValue }) => {
//     const token = localStorage.getItem("token");

//     // Check if the token is expired
//     if (!checkTokenExpiry(token)) {
//       return rejectWithValue("Token expired. Please log in again.");
//     }

//     try {
//       const response = await fetch("http://localhost:3001/tickets/generate", {
//         method: "POST",
//         headers: {
//           "Content-Type": "application/json",
//           Authorization: `Bearer ${token}`, // Add Authorization header
//         },
//         credentials: "include",
//         body: JSON.stringify(ticketData), // Pass the ticket data in the request body
//       });
//       console.log(ticketData);

//       if (!response.ok) {
//         const errorMessage = await response.json();
//         throw new Error(`Error: ${response.status} - ${errorMessage.message}`);
//       }

//       return await response.json(); // Assuming your API returns the newly created ticket
//     } catch (error) {
//       return rejectWithValue(error.message);
//     }
//   }
// );


// Create an async thunk for generating (creating) a ticket
export const createTicket = createAsyncThunk(
  "tickets/createTicket",
  async (ticketData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");

    // Check if the token is expired
    if (!checkTokenExpiry(token)) {
      return rejectWithValue("Token expired. Please log in again.");
    }

    try {
      // Use axiosInstance for creating a ticket
      const response = await axiosInstance.post("/tickets/generate", ticketData, {
        headers: {
          Authorization: `Bearer ${token}`, // Add Authorization header
        },
      });

      return response.data; // Assuming your API returns the newly created ticket
    } catch (error) {
      return rejectWithValue(error.response?.data?.message || error.message);
    }
  }
);

const ticketsSlice = createSlice({
  name: "tickets",
  initialState: {
    tickets: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets = action.payload;
      })
      .addCase(fetchTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createTicket.pending, (state) => {
        state.loading = true;
        state.error = null; // Clear previous errors
      })
      .addCase(createTicket.fulfilled, (state, action) => {
        state.loading = false;
        state.tickets.push(action.payload); // Add the newly created ticket to the state
      })
      .addCase(createTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message; // Capture the error message
      });
  },
});

export default ticketsSlice.reducer;
