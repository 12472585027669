import React from "react";

const Input = ({ type, name, value, onChange, placeholder, icon }) => {
  return (
    <div>
      <label htmlFor={name} className="sr-only">
        {placeholder}
      </label>
      <div className="relative">
        <input
          type={type}
          name={name}
          value={value}
          onChange={onChange}
          className="w-full rounded-lg placeholder-gray-300 bg-white bg-opacity-30 p-3 pe-12 text-sm shadow-sm"
          placeholder={placeholder}
        />
        {icon && (
          <span className="absolute inset-y-0 end-0 grid place-content-center px-4">
            {icon}
          </span>
        )}
      </div>
    </div>
  );
};

export default Input;
