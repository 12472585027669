import React, { useState } from 'react';
import Events from '../../components/dashboard/Events';
import Users from '../../components/dashboard/Users';
import Tickets from './../../components/dashboard/Tickets';

export default function Dashboard() {
  const [activeTab, setActiveTab] = useState('events');

  return (
    <div className="flex min-h-screen bg-gray-100">
      {/* Sidebar */}
      <div className="w-52 sticky top-0 h-screen bg-white shadow-md">
        <div className="p-4">
          <h1 className="text-2xl font-bold text-gray-800">Ticket System</h1>
        </div>
        <nav className="mt-4">
          <button
            className={`flex w-full items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${activeTab === 'events' ? 'bg-gray-200' : ''}`}
            onClick={() => setActiveTab('events')}
          >
            <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
            </svg>
            Events
          </button>
          <button
            className={`flex w-full items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${activeTab === 'users' ? 'bg-gray-200' : ''}`}
            onClick={() => setActiveTab('users')}
          >
            <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
            </svg>
            Users
          </button>
          <button
            className={`flex w-full items-center px-4 py-2 text-gray-700 hover:bg-gray-200 ${activeTab === 'tickets' ? 'bg-gray-200' : ''}`}
            onClick={() => setActiveTab('tickets')}
          >
            <svg className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
            </svg>
            Tickets
          </button>
        </nav>
      </div>

      {/* Main content */}
      <div className="flex-1 overflow-auto">
        <div className="container mx-auto p-6">          
          {activeTab === 'events' && (
            <>
              <Events/>
            </>
          )}

          {activeTab === 'users' && (
            <>
                <Users/>
            </>
          )}

          {activeTab === 'tickets' && (
            <>
              <Tickets/>
            </>
          )}
        </div>
      </div>
    </div>
  );
}