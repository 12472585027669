import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from "chart.js";

// Register the necessary components
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const ActiveUserChart = ({ tickets = [] }) => {
  // Count the number of tickets per user
  const userTicketsCount = {};

  tickets.forEach((ticket) => {
    const userName = ticket.fullName; // Assuming fullName is the user's name
    if (userTicketsCount[userName]) {
      userTicketsCount[userName]++;
    } else {
      userTicketsCount[userName] = 1;
    }
  });

  const data = {
    labels: Object.keys(userTicketsCount),
    datasets: [
      {
        label: "Tickets Purchased per user",
        data: Object.values(userTicketsCount),
        backgroundColor: "rgba(75, 192, 192, 0.6)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    // scales: {
    //   x: {
    //     title: {
    //       display: true,
    //       text: "Users",
    //     },
    //   },
    //   y: {
    //     title: {
    //       display: true,
    //       text: "Number of Tickets",
    //     },
    //     beginAtZero: true,
    //   },
    // },
  };

  return (
    <div className="h-60">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ActiveUserChart;
