// EventCategoryChart.js
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

// Register the required components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

// Define a mapping of category names to colors
const categoryColors = {
  Concerts: 'rgba(75, 192, 192, 0.6)',
  Educational: 'rgba(255, 99, 132, 0.6)',
  Comedy: 'rgba(255, 206, 86, 0.6)',
  Theater: 'rgba(107, 33, 168, 0.6)',
  Sports: 'rgba(22, 101, 52, 0.6)',
// Add more categories and their respective colors as needed
};

const EventCategoryChart = ({ eventCounts }) => {
  // Prepare data for the bar chart
  const labels = Object.keys(eventCounts);
  const data = {
    labels,
    datasets: [
      {
        label: 'Number of Events per category',
        data: Object.values(eventCounts),
        backgroundColor: labels.map(label => categoryColors[label] || 'rgba(0, 0, 0, 0.6)'), // Default to black if category not found
        borderColor: labels.map(label => categoryColors[label] ? categoryColors[label].replace(/0\.6/, '1') : 'rgba(0, 0, 0, 1)'), // Full opacity for borders
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  return (
    <div className="h-56">
      <Bar data={data} options={options} />
    </div>
  );
};

export default EventCategoryChart;
