// src/charts/EventLineChart.js

import React from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const EventLineChart = ({ eventCount }) => {
  const months = [
    "January", "February", "March", "April", "May", "June", 
    "July", "August", "September", "October", "November", "December"
  ];

  const data = {
    labels: months,
    datasets: [
      {
        label: "Number of Events per month",
        data: months.map(month => eventCount[month] || 0), // Map counts or 0 if not found
        fill: false,
        borderColor: "rgba(75, 192, 192, 1)",
        tension: 0.1,
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    //   title: {
    //     display: true,
    //     text: "Events per Month",
    //   },
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="w-full h-56">
      <Line data={data} options={options} />
    </div>
  );
};

export default EventLineChart;
