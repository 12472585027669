import { configureStore } from '@reduxjs/toolkit';
import eventsReducer from './eventsSlice';
import usersReducer from './usersSlice';
import authReducer from './authSlice';
import ticketsReducer from './ticketsSlice';

const store = configureStore({
  reducer: {
    auth: authReducer,
    events: eventsReducer,
    users: usersReducer,
    tickets: ticketsReducer
  },
});

export default store;
