import React from "react";
import { Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

// Register the necessary components
ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale, LinearScale);

const TicketChart = ({ tickets }) => {
  // Aggregate tickets by category
  const categoryCounts = tickets.reduce((acc, ticket) => {
    acc[ticket.category] = (acc[ticket.category] || 0) + 1;
    return acc;
  }, {});

  // Define colors for each category
  const categoryColors = {
    Concerts: 'rgba(75, 192, 192, 0.6)',
    Educational: 'rgba(255, 99, 132, 0.6)',
    Comedy: 'rgba(255, 206, 86, 0.6)',
    Theater: 'rgba(107, 33, 168, 0.6)',
    Sports: 'rgba(22, 101, 52, 0.6)',
    // Add more categories and their colors as needed
  };

  // Create an array of colors based on the categories present in the data
  const backgroundColors = Object.keys(categoryCounts).map(category => {
    return categoryColors[category] || 'rgba(0, 0, 0, 0.6)'; // Default color if category not found
  });

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false, // Set to false to control height and width
  };

  const chartData = {
    labels: Object.keys(categoryCounts),
    datasets: [
      {
        label: "Tickets Sold",
        data: Object.values(categoryCounts),
        backgroundColor: backgroundColors, // Use the array of colors here
        borderColor: backgroundColors.map(color => color.replace('0.6', '1')), // Make borders fully opaque
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="h-56">
      <Pie data={chartData} options={chartOptions} />
    </div>
  );
};

export default TicketChart;
