import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "./axiosInstance";

// Token expiry check function (example logic based on JWT decoding)
const checkTokenExpiry = (token) => {
  if (!token) return false;
  const decodedToken = jwtDecode(token); // Decode token to check expiration
  const currentTime = Date.now() / 1000; // Get current time in seconds
  return decodedToken.exp > currentTime; // true if token is still valid
};
// Create async thunk for fetching users
export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.get("/users");
      const users = await response.data;
      return users; // Assuming your API returns JSON data
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

// Thunk for creating a new user
export const createUser = createAsyncThunk(
  "users/createUser",
  async (newUserData, { rejectWithValue }) => {
    try {
      const token = localStorage.getItem("token"); // Assuming you are using JWT for authorization

      // If the token is expired, reject with error
      if (!token || !checkTokenExpiry(token)) {
        throw new Error("Token expired. Please log in again.");
      }
      // #update : use axiosInstance instead of fetch :

      // const response = await fetch("http://localhost:3001/users/adduser", {
      //   method: "POST",
      //   headers: {
      //     "Content-Type": "application/json",
      //     Authorization: `Bearer ${token}`, // Add Authorization header with token
      //   },
      //   credentials: "include",
      //   body: JSON.stringify(newUserData),
      // });

      // if (!response.ok) {
      //   const errorMessage = await response.json(); // Get the error message from response
      //   console.error("Error Response:", errorMessage); // Log the error response
      //   throw new Error(`Error: ${response.status} - ${errorMessage.message}`);
      // }

      // return await response.json(); // Assuming your API returns user data

      const response = await axiosInstance.post("/users/adduser", newUserData, {
        headers: {
          Authorization: `Bearer ${token}`, // Add Authorization header with token
        },
      });

      return response.data;
    } catch (error) {
      return rejectWithValue("Server error. Could not create user.");
    }
  }
);

// export const updateUser = createAsyncThunk(
//   "users/updateUser",
//   async (userData) => {
//     const token = localStorage.getItem("token");
//     // Check if the token is expired
//     if (!checkTokenExpiry(token)) {
//       throw new Error("Token expired. Please log in again.");
//     }
//     console.log("Token:", token); // Log token only here
//     // #update : use axiosInstance instead of fetch :

//     const response = await fetch(`http://localhost:3001/users/${userData.id}`, {
//       method: "PUT",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`, // Add Authorization header
//       },
//       credentials: "include",
//       body: JSON.stringify(userData),
//     });
//     console.log(response);

//     if (!response.ok) {
//       throw new Error("Failed to update event");
//     }
//     return await response.json();
//   }
// );

// Thunk for updating a user using axiosInstance
export const updateUser = createAsyncThunk(
  "users/updateUser",
  async (userData, { rejectWithValue }) => {
    const token = localStorage.getItem("token");
    // Check if the token is expired
    if (!checkTokenExpiry(token)) {
      throw new Error("Token expired. Please log in again.");
    }

    try {
      const response = await axiosInstance.put(
        `/users/${userData.id}`,
        userData,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Add Authorization header
          },
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to update user");
    }
  }
);

//Soft Delete user
// export const softDeleteUser = (userId) => async (dispatch) => {
//   try {
//     // #update : use axiosInstance instead of fetch :

//     const response = await fetch(`http://localhost:3001/users/${userId}`, {
//       method: "PATCH",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ isDeleted: true }), // Mark the user as deleted
//     });

//     if (!response.ok) {
//       throw new Error("Failed to soft delete user");
//     }

//     // Fetch the updated users after deletion
//     dispatch(fetchUsers());
//   } catch (error) {
//     console.error("Failed to delete user:", error);
//   }
// };

// Soft Delete user using axiosInstance
export const softDeleteUser = createAsyncThunk(
  "users/softDeleteUser",
  async (userId, { dispatch, rejectWithValue }) => {
    try {
      const response = await axiosInstance.patch(
        `/users/${userId}`,
        { isDeleted: true } // Mark the user as deleted
      );

      if (response.status !== 200) {
        throw new Error("Failed to soft delete user");
      }

      // Fetch the updated users after deletion
      dispatch(fetchUsers());
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data || "Failed to delete user");
    }
  }
);

// Create the slice
const usersSlice = createSlice({
  name: "users",
  initialState: {
    users: [], // Initialize users array here
    loading: false,
    error: null,
    successMessage: null,
  },
  reducers: {
    setUser: (state, action) => {
      const { id, ...updatedData } = action.payload;
      const index = state.users.findIndex((user) => user._id === id);
      if (index !== -1) {
        state.users[index] = { ...state.users[index], ...updatedData };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch users
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload; // Assign the fetched users here
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });

    // Create user
    builder
      .addCase(createUser.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.successMessage = null;
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload.user); // Add the new user to the list
        state.successMessage = "User created successfully!";
      })
      .addCase(createUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || "Failed to create user";
      });

    // Update Event
    builder
      .addCase(updateUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.loading = false;
        state.users.push(action.payload);
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default usersSlice.reducer;
