import React, { useEffect, useState } from "react";
import EventCard from "../../components/EventCard";
import { Link } from "react-router-dom";
import HeroSection from "../../components/HeroSection";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../store/eventsSlice";

export default function Component() {
  const dispatch = useDispatch();
  const events = useSelector((state) => state.events.events);
  const loading = useSelector((state) => state.events.loading);
  const error = useSelector((state) => state.events.error);
  const [visibleCards, setVisibleCards] = useState(4); // Initially show 4 cards

  //Verify user authentication state
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const showMoreCards = () => {
    setVisibleCards((prevCount) => prevCount + 4); // Increase the visible cards by 4 each time
  };
   // Function to check if an event has passed
   const isEventUpcoming = (eventDate) => {
    const currentDate = new Date(); // Get the current date
    const eventDateObj = new Date(eventDate); // Convert event date to a Date object
    return eventDateObj >= currentDate; // Return true if event is today or in the future
  };

  useEffect(() => {
    dispatch(fetchEvents());
  }, [dispatch]);
 // Filter upcoming events outside of the return statement for clarity
 const upcomingEvents = events
  .filter((event) => isEventUpcoming(event.date)) // Filter for upcoming events
  .reverse(); // Sort from recently added to last added
  return (
    <div className="min-h-screen bg-gray-100">
      <HeroSection
        image="https://images.pexels.com/photos/3052360/pexels-photo-3052360.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        title="Discover Amazing Events"
        subtitle="Find and book tickets for the hottest events in your area"
        additionalContent={
          !isLoggedIn && ( // Conditionally render additionalContent only if the user is not logged in
            <div className="flex flex-col mb-8 lg:mb-16 space-y-4 sm:flex-row sm:justify-center sm:space-y-0 sm:space-x-4">
              <span className="inline-flex justify-center items-center py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-primary-700 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300">
                Get Started
                <svg
                  className="ml-2 -mr-1 w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
              <Link
                to="/register"
                className="rounded-md px-7 py-3 text-md font-medium bg-gray-200 hover:bg-gray-300 text-gray-800 transition duration-300"
              >
                Create Account
              </Link>
            </div>
          )
        }
      />

      <div className=" bg-gray-100">
        <section className="bg-white">
          <div className="py-4 px-2 mx-auto sm:py-4 lg:px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-5 gap-4 h-full">
              <div className="col-span-2 sm:col-span-1 md:col-span-2 bg-gray-50 h-auto md:h-full flex flex-col">
                <Link
                  to="/category/Concerts"
                  className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"
                >
                  <img
                    src="https://images.pexels.com/photos/196652/pexels-photo-196652.jpeg"
                    alt="Concert"
                    className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    Live Concert
                  </h3>
                </Link>
              </div>
              <div className="col-span-2 sm:col-span-1 md:col-span-2 bg-stone-50">
                <Link
                  to="/category/Theater"
                  className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 mb-4"
                >
                  <img
                    src="https://images.pexels.com/photos/713149/pexels-photo-713149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                    alt="Theater"
                    className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    Theater Show
                  </h3>
                </Link>
                <div className="grid gap-4 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2">
                  <Link
                    to="/category/Comedy"
                    className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
                  >
                    <img
                      src="https://offloadmedia.feverup.com/secretldn.com/wp-content/uploads/2022/09/25135358/story-party-1024x683.jpg"
                      alt="Comedy Night"
                      className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                    />
                    <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                    <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                      Comedy Night
                    </h3>
                  </Link>
                  <Link
                    to="/category/Sports"
                    className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40"
                  >
                    <img
                      src="https://images.pexels.com/photos/11644798/pexels-photo-11644798.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
                      alt="Sports Show"
                      className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                    />
                    <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                    <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                      Sports Show
                    </h3>
                  </Link>
                </div>
              </div>
              <div className="col-span-2 sm:col-span-1 md:col-span-1 bg-sky-50 h-auto md:h-full flex flex-col">
                <Link
                  to="/category/Educational"
                  className="group relative flex flex-col overflow-hidden rounded-lg px-4 pb-4 pt-40 flex-grow"
                >
                  <img
                    src="https://images.pexels.com/photos/3767170/pexels-photo-3767170.jpeg?auto=compress&cs=tinysrgb&w=400"
                    alt="Educational"
                    className="absolute inset-0 h-full w-full object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out"
                  />
                  <div className="absolute inset-0 bg-gradient-to-b from-gray-900/25 to-gray-900/5"></div>
                  <h3 className="z-10 text-2xl font-medium text-white absolute top-0 left-0 p-4 xs:text-xl md:text-3xl">
                    Educational
                  </h3>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="container mx-auto py-16 px-4">
          <h2 className="text-3xl font-bold mb-3 text-center">
            Upcoming Events
          </h2>
          <div className="relative">
            <div className="flex gap-4 p-4 flex-wrap justify-center">
              {loading && (
                <div className="border-gray-300 mx-auto h-20 w-20 animate-spin rounded-full border-8 border-t-gray-600" />
              )}
              {error && <p>Error: {error}</p>}
              
              {upcomingEvents                
                .slice(0, visibleCards)
                .map((event) => (
                <EventCard key={event.id} event={event} />
              ))}
            </div>
            {/* Show button only if there are more cards to display */}
            {visibleCards < upcomingEvents.length && (
              <div className="flex justify-center mt-5">
                <button
                  onClick={showMoreCards}
                  className="py-2 px-4 border border-primary rounded-md bg-white text-primary font-semibold text-sm hover:bg-gray-50"
                >
                  View more
                </button>
              </div>
            )}
          </div>
        </section>
      </div>
    </div>
  );
}
