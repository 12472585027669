import "./App.css";
import Home from "./pages/Home";
import Register from "./pages/register";
import Login from "./pages/login";
import Dashboard from "./pages/dashboard";
import Layout from "./components/Layout";
import { Routes, Route, Navigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setUser } from "./store/authSlice";
import EventsList from "./components/EventsList";
import { useEffect } from "react";

function App() {
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedToken = localStorage.getItem("token");

    if (storedUser && storedToken) {
      dispatch(setUser(JSON.parse(storedUser))); // Restore user
    }
  }, [dispatch]);

  //test commit

  return (
    <div>
      <Layout
      // isLoggedIn={isLoggedIn} user={user}
      >
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/category/:category" element={<EventsList />} />
          <Route path="/register" element={<Register />} />

          <Route
            path="/login"
            element={isLoggedIn ? <Navigate to="/" /> : <Login />}
          />
          <Route
            path="/dashboard"
            element={
              isLoggedIn && user?.role === "admin" ? (
                <Dashboard />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Layout>
    </div>
  );
}

export default App;
