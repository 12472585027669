import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers, softDeleteUser } from "../../store/usersSlice";
import Pagination from "./Pagination";
import AddUser from "../AddUser";
import Toast from "./Toast";
import EditUser from "../EditUser";
import ConfirmationModal from "../ConfirmationModal";
import ActiveUserChart from "../charts/ActiveUserChart";

const Users = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [sortBy, setSortBy] = useState("name");
  const [filterRole, setFilterRole] = useState("all");
  const [toastVisible, setToastVisible] = useState(false); // State for toast visibility
  const [toastMessage, setToastMessage] = useState(""); // State for toast message
  const [fadeOut, setFadeOut] = useState(false); // New state for fade out
  const [selectedUser, setSelectedUser] = useState(null); // State to hold the selected User for editing
  const [isModalOpen, setIsModalOpen] = useState(false); // State to manage modal visibility
  const [userToDelete, setUserToDelete] = useState(null); // State to hold user ID for deletion

  const [currentPage, setCurrentPage] = useState(1); // Current page state
  const usersPerPage = 5; // Number of users per page

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users.users);
  const tickets = useSelector((state) => state.tickets.tickets);

  useEffect(() => {
    dispatch(fetchUsers());
  }, [dispatch]);

  // Logic for pagination
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;

  // Filtering and sorting logic
  const filteredUsers = users
    .filter((user) => filterRole === "all" || user.role === filterRole)
    .filter((user) => {
      const firstname = user.firstname || ""; // Fallback to an empty string if undefined
      const lastname = user.lastname || ""; // Fallback to an empty string if undefined
      const email = user.email || ""; // Fallback to an empty string if undefined

      return (
        firstname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        lastname.toLowerCase().includes(searchTerm.toLowerCase()) ||
        email.toLowerCase().includes(searchTerm.toLowerCase())
      );
    })
    .filter((user) => !user.isDeleted); // Exclude deleted users

  // Sorting logic
  const sortedUsers = [...filteredUsers].sort((a, b) => {
    if (sortBy === "nameAsc") {
      return a.firstname.localeCompare(b.firstname);
    } else if (sortBy === "nameDesc") {
      return b.firstname.localeCompare(a.firstname);
    }
    return 0; // Default case (no sorting)
  });

  const currentUsers = sortedUsers.slice(indexOfFirstUser, indexOfLastUser);

  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  // Function to open the confirmation modal
  const openConfirmationModal = (user) => {
    setUserToDelete(user);
    setIsModalOpen(true);
  };

  // Function to handle confirmation of deletion
  const confirmDelete = () => {
    if (userToDelete) {
      handleSoftDelete(userToDelete._id); // Call the delete function with the user ID
    }
    setIsModalOpen(false); // Close the modal after confirmation
    setUserToDelete(null); // Reset the user to delete
  };
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isOpenEdit, setIsOpenEdit] = useState(false);

  const formRef = useRef(null);

  const toggleAddPopup = () => {
    setIsOpenAdd(!isOpenAdd);
  };
  // Close the popup when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setIsOpenAdd(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]); // Only need formRef as dependency

  const toggleEditPopup = () => {
    setIsOpenEdit(!isOpenEdit);
  };

  // Function to handle edit button click
  const handleEditClick = (user) => {
    setSelectedUser(user); // Set the selected event to edit
    toggleEditPopup(); // Open the edit popup
  };

  const closeToastAnimation = () => {
    setTimeout(() => {
      setFadeOut(true); // Trigger fade out effect
      setTimeout(() => {
        setToastVisible(false); // Hide the toast after fade out
        setFadeOut(false); // Reset fade out state
      }, 1000); // Match this duration to the CSS transition
    }, 10000); // Keep the toast visible for 5 seconds
  };

  // Function to handle successful addition of an event
  const handleAddSuccess = () => {
    setToastMessage("User added successfully!");
    setToastVisible(true); // Show the toast
    closeToastAnimation();
  };

  // Function to handle successful update of an event
  const handleUpdateSuccess = () => {
    setToastMessage("User updated successfully!");
    setToastVisible(true);
    closeToastAnimation();
  };

  const closeToast = () => {
    setToastVisible(false);
  };
  // Function to handle successful deletion of an event
  const handleDeleteSuccess = () => {
    setToastMessage("User deleted successfully!");
    setToastVisible(true);
    closeToastAnimation();
  };

  // New function to handle soft delete
  const handleSoftDelete = (userId) => {
    console.log("Attempting to delete event with ID:", userId); // Log the event ID
    if (userId) {
      dispatch(softDeleteUser(userId)); // Dispatch the action to soft delete the event
      handleDeleteSuccess();
    } else {
      console.error("Event ID is undefined");
    }
  };

  return (
    <div className=" p-6 rounded-lg shadow bg-white">
      {/* Toast Notification */}
      {toastVisible && (
        <Toast
          icon={
            <svg
              className="w-5 h-5"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 20 20"
            >
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z" />
            </svg>
          } // Replace with your success icon
          message={toastMessage}
          onclick={closeToast}
          visible={toastVisible}
          fade={fadeOut}
        />
      )}

      {/* Confirmation Modal */}
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={confirmDelete}
        object={
          userToDelete
            ? userToDelete.firstname + " " + userToDelete.lastname
            : ""
        }
      />
      <div className="flex justify-between mb-6">
        <h1 className="text-3xl font-bold text-gray-800">User Management</h1>
        <button
          onClick={toggleAddPopup}
          // className=" bg-gray-800 hover:shadow-lg p-1 rounded-full"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="2.5em"
            height="2.5em"
            viewBox="0 0 48 48"
          >
            <defs>
              <mask id="ipSAddOne0">
                <g fill="none" strokeLinejoin="round" strokeWidth="4">
                  <path
                    fill="#fff"
                    stroke="#fff"
                    d="M24 44c11.046 0 20-8.954 20-20S35.046 4 24 4S4 12.954 4 24s8.954 20 20 20Z"
                  />
                  <path
                    stroke="#000"
                    strokeLinecap="round"
                    d="M24 16v16m-8-8h16"
                  />
                </g>
              </mask>
            </defs>
            <path fill="black" d="M0 0h48v48H0z" mask="url(#ipSAddOne0)" />
          </svg>
        </button>
        {isOpenAdd && (
          <div ref={formRef}>
            <AddUser onClose={toggleAddPopup} onAddSuccess={handleAddSuccess} />
          </div>
        )}
      </div>
      <div className="mb-8 p-4">
        <ActiveUserChart tickets={tickets} />
      </div>
      <div className="mb-6 flex flex-col sm:flex-row items-center gap-4">
        <div className="relative flex-grow">
          <input
            type="text"
            placeholder="Search events..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <svg
            className="absolute left-3 top-2.5 h-5 w-5 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            />
          </svg>
        </div>
        <div className="flex items-center gap-2">
          <svg
            className="h-5 w-5 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 4h13M3 8h9m-9 4h6m4 0l4-4m0 0l4 4m-4-4v12"
            />
          </svg>
          <select
            className="pl-2 pr-8 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          >
            <option value="default">Sort by default</option>
            <option value="nameAsc">Sort by Name (A-Z)</option>
            <option value="nameDesc">Sort by Name (Z-A)</option>
          </select>
        </div>
        <div className="flex items-center gap-2">
          <svg
            className="h-5 w-5 text-gray-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2.586a1 1 0 01-.293.707l-6.414 6.414a1 1 0 00-.293.707V17l-4 4v-6.586a1 1 0 00-.293-.707L3.293 7.293A1 1 0 013 6.586V4z"
            />
          </svg>
          <select
            className="pl-2 pr-8 py-2 border border-gray-300 rounded-lg appearance-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            value={filterRole}
            onChange={(e) => setFilterRole(e.target.value)}
          >
            <option value="all">All Roles</option>
            <option value="admin">Admin</option>
            <option value="user">User</option>
          </select>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow overflow-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr className="bg-gray-100">
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                User ID
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Full Name
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Phone Number
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {currentUsers
              .filter((user) => !user.isDeleted)
              .map((user, index) => (
                <tr
                  key={user._id || index}
                  className="hover:bg-gray-50 transition-colors duration-200"
                >
                  <td className="px-6 py-4  text-sm  font-medium text-gray-900">
                    {user._id}
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                    {user.firstname} {user.lastname}
                  </td>
                  <td className="px-6 py-4  text-sm text-gray-500">
                    {user.email}
                  </td>
                  <td className="px-6 py-4  text-sm text-gray-500">
                    {user.phoneNumber ? user.phoneNumber : "-"}
                  </td>
                  <td className="px-6 py-4  text-sm text-gray-500">
                    <span
                      className={`${
                        user.role === "admin"
                          ? "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-green-100 text-green-800"
                          : "px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-yellow-100 text-yellow-800"
                      }`}
                    >
                      {user.role}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
                    <button
                      onClick={() => handleEditClick(user)}
                      className="text-indigo-600 hover:text-indigo-900 mr-4"
                    >
                      <svg
                        className="h-5 w-5 inline-block mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
                        />
                      </svg>
                      Edit
                    </button>
                    {isOpenEdit && (
                      <div ref={formRef}>
                        <EditUser
                          onClose={toggleEditPopup}
                          user={selectedUser}
                          onUpdateSuccess={handleUpdateSuccess}
                        />
                      </div>
                    )}
                    <button
                      onClick={() => openConfirmationModal(user)}
                      className="text-red-600 hover:text-red-900"
                    >
                      <svg
                        className="h-5 w-5 inline-block mr-1"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
        />
      </div>
    </div>
  );
};

export default Users;
