import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Input from "../../components/Input";
import { useDispatch, useSelector } from "react-redux";
import { clearMessage, login } from "../../store/authSlice";

const Login = () => {
  // Accept setUser prop
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false); // State to toggle password visibility

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev); // Toggle the visibility state
  };

  const dispatch = useDispatch(); // Initialize useDispatch for dispatching actions
  const { message, isLoading } = useSelector((state) => state.auth);

  const navigate = useNavigate(); // Initialize useNavigate for redirection

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Dispatch the login action
      const resultAction = await dispatch(login({ email, password }));

      // Check if the login was successful
      if (login.fulfilled.match(resultAction)) {
        console.log("User logged in:", resultAction.payload.user);
        navigate("/dashboard"); // Navigate to dashboard on successful login
      } else {
        console.log(message);
        // Display error message
      }
    } catch (error) {
      console.error("Error logging in:", error);
    }
  };

  useEffect(() => {
    // Clear the message when the component mounts
    dispatch(clearMessage());
  }, [dispatch]);
  return (
    <div
      className="relative"
      style={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80')",
          backgroundSize:"cover"
      }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="mx-auto max-w-lg relative z-10 flex flex-col justify-center min-h-[100dvh]">
        <h1 className="text-center text-2xl font-bold text-gray-200 sm:text-3xl">
          Welcome Back
        </h1>
        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          Please enter your email and password to continue.
        </p>

        {message && (
          <p
            className={`text-center mt-4 ${
              message.toLowerCase().includes("success")
                ? "text-green-500"
                : "text-red-500"
            }`}
          >
            {message}
          </p>
        )}

        <form
          onSubmit={handleSubmit}
          className=" text-white space-y-4 py-4 sm:py-6 lg:py-8"
        >
          <Input
            type="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter email"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            }
          />
          <Input
            type={showPassword ? "text" : "password"} // Change input type based on state
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            icon={
              <svg
                onClick={togglePasswordVisibility} // Add click event to icon
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
                className="size-4 text-gray-400 cursor-pointer"
              >
                {showPassword ? ( // Show this icon if the password is visible
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M2 5.27L3.28 4L20 20.72L18.73 22l-3.08-3.08c-1.15.38-2.37.58-3.65.58c-5 0-9.27-3.11-11-7.5c.69-1.76 1.79-3.31 3.19-4.54zM12 9a3 3 0 0 1 3 3a3 3 0 0 1-.17 1L11 9.17A3 3 0 0 1 12 9m0-4.5c5 0 9.27 3.11 11 7.5a11.8 11.8 0 0 1-4 5.19l-1.42-1.43A9.86 9.86 0 0 0 20.82 12A9.82 9.82 0 0 0 12 6.5c-1.09 0-2.16.18-3.16.5L7.3 5.47c1.44-.62 3.03-.97 4.7-.97M3.18 12A9.82 9.82 0 0 0 12 17.5c.69 0 1.37-.07 2-.21L11.72 15A3.064 3.064 0 0 1 9 12.28L5.6 8.87c-.99.85-1.82 1.91-2.42 3.13"
                    />
                  </svg>
                ) : (
                  // Show this icon if the password is hidden
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1.5em"
                    height="1.5em"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M12 9a3 3 0 0 1 3 3a3 3 0 0 1-3 3a3 3 0 0 1-3-3a3 3 0 0 1 3-3m0-4.5c5 0 9.27 3.11 11 7.5c-1.73 4.39-6 7.5-11 7.5S2.73 16.39 1 12c1.73-4.39 6-7.5 11-7.5M3.18 12a9.821 9.821 0 0 0 17.64 0a9.821 9.821 0 0 0-17.64 0"
                    />
                  </svg>
                )}
              </svg>
            }
          />

          <button
            type="submit"
            disabled={isLoading}
            className="block w-full rounded-lg bg-gray-200 hover:bg-gray-300 text-gray-800 transition duration-300 px-5 py-3 text-sm font-medium"
          >
            {isLoading ? "Logging in..." : "Login"}
          </button>
        <p className="text-center text-gray-200 mt-5 text-sm">
          Don't have an account?{" "}
          <Link to="/register" className="underline ml-1">
            Register here
          </Link>
        </p>
        </form>

      </div>
    </div>
  );
};

export default Login;
