import React from "react";

const HeroSection = ({ image, title, subtitle, additionalContent }) => {
  return (
    <section
      className="overflow-hidden bg-cover bg-center h-[500px]"
      style={{ backgroundImage: `url(${image})` }}
    >
      <div className="bg-black/50 p-8 md:p-12 lg:px-16 lg:py-24 h-[500px]">
        <div className="z-10 flex flex-col items-center justify-center h-full text-white">
          <h1 className="text-5xl font-bold mb-4 text-center">{title}</h1>
          <p className="text-xl mb-8 text-center">{subtitle}</p>
          {additionalContent}
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
