import React from "react";
import logo from "../assets/images/tikita.png";
import logofeizhou from "../assets/images/logofeizhou.png";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {
  const user = useSelector((state) => state.auth.user); // Adjust the state path to match your Redux store structure

  const links = [
    { name: "All Events", href: "/" },
    { name: "Concerts", href: "/category/Concerts" },
    { name: "Theater", href: "/category/Theater" },
    { name: "Comedy", href: "/category/Comedy" },
    { name: "Sports", href: "/category/Sports" },
    { name: "Educational", href: "/category/Educational" },
  ];

  return (
    <footer className="bg-gray-900">
      {!user || (user && user.role === "user") ? (
        <div className="mx-auto max-w-5xl px-4 pt-10 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center gap-4 py-4">
            <Link to="/" onClick={() => window.scrollTo(0, 0)}>
              <img
                src={logo}
                alt="Logo Tikita"
                className="h-12 transition-transform duration-300 ease-in-out hover:scale-105"
              />
            </Link>
            <span className="text-white text-sm font-medium">Powered by</span>
            <a
              href="https://feizhoucom.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={logofeizhou}
                alt="Logo Feizhoucom"
                className="h-10 transition-transform duration-300 ease-in-out hover:scale-105"
              />
            </a>
          </div>
          <p className="mx-auto mt-6 max-w-4xl text-center text-gray-500">
            FeizhouCom is a Moroccan agency passionate about technology and
            innovation. We specialize in creating 10x faster and tailor-made
            websites and mobile applications that enable businesses to thrive in
            the digital age.
          </p>
          <ul className="mt-8 flex flex-wrap justify-center gap-6 md:gap-8 lg:gap-12">
            {links.map((link) => (
              <li key={link.name}>
                <Link
                  onClick={() => window.scrollTo(0, 0)}
                  className="text-gray-200 transition hover:text-gray-700/75"
                  to={link.href}
                >
                  {link.name}
                </Link>
              </li>
            ))}
          </ul>
          {/* Social Media Icons */}
          <ul className="mt-8 flex justify-center gap-6 md:gap-8">
            {/* Facebook */}
            <li>
              <a
                href="https://web.facebook.com/feizhoucomagency"
                rel="noreferrer"
                target="_blank"
                className="text-gray-400 transition hover:text-white"
              >
                <span className="sr-only">Facebook</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            {/* Instagram */}
            <li>
              <a
                href="https://www.instagram.com/feizhoucomagency/"
                rel="noreferrer"
                target="_blank"
                className="text-gray-400 transition hover:text-white"
              >
                <span className="sr-only">Instagram</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
            {/* Linkedin */}
            <li>
              <a
                href="https://www.linkedin.com/company/feizhoucom/"
                rel="noreferrer"
                target="_blank"
                className="text-gray-400 transition hover:text-white"
              >
                <span className="sr-only">Linkedin</span>
                <svg
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2zM8.337 17.016H5.834v-7.5h2.503v7.5zM7.084 8.37a1.501 1.501 0 1 1 0-3.002 1.501 1.501 0 0 1 0 3.002zm9.183 8.646h-2.501v-3.718c0-.888-.018-2.03-1.234-2.03-1.237 0-1.427.966-1.427 1.964v3.784h-2.5v-7.5h2.401v1.025h.034c.334-.63 1.152-1.294 2.369-1.294 2.532 0 3 1.667 3 3.83v3.939z"
                    clipRule="evenodd"
                  />
                </svg>
              </a>
            </li>
          </ul>{" "}
          <div className="flex items-center justify-center pb-3 pt-8 text-white text-sm font-light">
            &copy;&nbsp;{new Date().getFullYear()} Copyright:&nbsp;
            <a href="https://feizhoucom.com/" className="font-semibold">
              Feizhoucom
            </a>
          </div>
        </div>
      ) : (
        <div className="flex flex-col justify-end items-center mx-auto max-w-5xl px-4 pt-5 sm:px-6 lg:px-8">
          <div className="flex justify-center items-center gap-4 py-4">
            <img src={logo} alt="Logo Tikita" className="h-12" />
            <span className="text-white text-sm font-medium">Powered by</span>
            <a
              href="https://feizhoucom.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logofeizhou} alt="Logo Feizhoucom" className="h-10" />
            </a>
          </div>
          <div className="flex items-center justify-center py-3 text-white text-xs font-light">
            &copy;&nbsp;{new Date().getFullYear()} Copyright:&nbsp;
            <a href="https://feizhoucom.com/" className="font-semibold">
              Feizhoucom
            </a>
          </div>
        </div>
      )}
    </footer>
  );
};

export default Footer;
