import axios from "axios";

// Create Axios instance
const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_SERVER_ENDPOINT,
  withCredentials: true,
});

// Add a response interceptor to handle 401 errors (token expired)
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    if (error.response && error.response.status === 401) {
      // Notify the user that their session has expired
      // #update : replace alert message with a cool & clear toast message
      alert("Your session has expired. You will be logged out.");

      try {
        // Send logout request to the server
        await axiosInstance.get("/auth/logout");

        // Clear token and user data from local storage (log out)
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      } catch (logoutError) {
        console.error("Logout failed:", logoutError);
      }
      // Redirect to the login page
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;
