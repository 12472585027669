import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer';

const Layout = ({ children }) => { // Accept user prop
    return (
        <div>
            <Navbar/> {/* Pass user to Navbar */}
            <main>{children}</main>
            <Footer />
        </div>
    );
};

export default Layout;
