import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import Input from "../../components/Input";
import { register, clearMessage } from "../../store/authSlice";

const Index = () => {
  const dispatch = useDispatch();
  // Get the message and loading status from Redux state
  const { message, isLoading } = useSelector((state) => state.auth);

  // Define state for form fields
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    phoneNumber: "",
  });

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    dispatch(register(formData)); // Dispatch the registerUser action
  };

  useEffect(() => {
    // Clear the message when the component mounts
    dispatch(clearMessage());
  }, [dispatch]);
  return (
    <div
      className="relative"
      style={{
        backgroundImage:
          "url('https://images.unsplash.com/photo-1492684223066-81342ee5ff30?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2070&q=80')",
      }}
    >
      <div className="absolute inset-0 bg-black opacity-70"></div>
      <div className="mx-auto max-w-lg relative z-10 flex flex-col justify-center min-h-[100dvh]">
        <h1 className="text-center text-2xl font-bold text-gray-200 sm:text-3xl">
          Get started today
        </h1>

        <p className="mx-auto mt-4 max-w-md text-center text-gray-500">
          Please enter your informations to continue.
        </p>

        {message && (
          <div className="flex items-center justify-center mt-4">
            <p
              className={`text-center mr-3 ${
                message.toLowerCase().includes("success")
                  ? "text-green-500"
                  : "text-red-500"
              }`}
            >
              {message}
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.3em"
              height="1.3em"
              viewBox="0 0 24 24"
            >
              <path
                fill="white"
                d="M20 8H8l1.212-3.03a2 2 0 0 0-1.225-2.641l-.34-.113a1 1 0 0 0-1.084.309L2.231 7.722a1 1 0 0 0-.231.64V19a2 2 0 0 0 2 2h7.21a2 2 0 0 0 1.987-1.779L14 12h6a2 2 0 0 0 0-4"
              />
            </svg>
            <Link to="/login" className=" underline text-blue-500 ml-1">
              Login Now
            </Link>
          </div>
        )}

        <form
          onSubmit={handleSubmit}
          className="text-white space-y-4 py-4 sm:py-6 lg:py-8"
        >
          <div className="grid grid-cols-2 gap-3">
            <Input
              type="text"
              name="firstname"
              value={formData.firstname}
              onChange={handleChange}
              placeholder="First name"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#adadad"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0a3.75 3.75 0 0 1 7.5 0M4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.9 17.9 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632"
                  />
                </svg>
              }
            />
            <Input
              type="text"
              name="lastname"
              value={formData.lastname}
              onChange={handleChange}
              placeholder="Last name"
              icon={
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="1.2em"
                  height="1.2em"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#adadad"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0a3.75 3.75 0 0 1 7.5 0M4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.9 17.9 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632"
                  />
                </svg>
              }
            />
          </div>

          <Input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Enter email"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="size-4 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207"
                />
              </svg>
            }
          />

          <Input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            placeholder="Enter password"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 24 24"
              >
                <path
                  fill="none"
                  stroke="#adadad"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="1.5"
                  d="M8 10V8c0-2.761 1.239-5 4-5s4 2.239 4 5v2M3.5 17.8v-4.6c0-1.12 0-1.68.218-2.107a2 2 0 0 1 .874-.875c.428-.217.988-.217 2.108-.217h10.6c1.12 0 1.68 0 2.108.217a2 2 0 0 1 .874.874c.218.428.218.988.218 2.108v4.6c0 1.12 0 1.68-.218 2.108a2 2 0 0 1-.874.874C18.98 21 18.42 21 17.3 21H6.7c-1.12 0-1.68 0-2.108-.218a2 2 0 0 1-.874-.874C3.5 19.481 3.5 18.921 3.5 17.8z"
                />
              </svg>
            }
          />

          <Input
            type="text"
            name="phoneNumber"
            value={formData.phoneNumber}
            onChange={handleChange}
            placeholder="Enter phone number"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="1.2em"
                height="1.2em"
                viewBox="0 0 2048 2048"
              >
                <path
                  fill="#adadad"
                  d="M1607 1213q44 0 84 16t72 48l220 220q31 31 47 71t17 85q0 44-16 84t-48 72l-14 14q-54 54-99 96t-94 70t-109 44t-143 15q-125 0-257-39t-262-108t-256-164t-237-207t-206-238t-162-256T38 775T0 523q0-83 14-142t43-108t70-93t96-99l16-16q31-31 71-48t85-17q44 0 84 17t72 48l220 220q31 31 47 71t17 85q0 44-15 78t-37 63t-48 51t-49 45t-37 44t-15 49q0 38 27 65l551 551q27 27 65 27q26 0 48-15t45-37t45-48t51-49t62-37t79-15m-83 707q72 0 120-13t88-39t76-64t85-86q27-27 27-65q0-18-14-42t-38-52t-51-55t-56-54t-51-47t-37-35q-27-27-66-27q-26 0-48 15t-44 37t-45 48t-52 49t-62 37t-79 15q-44 0-84-16t-72-48L570 927q-31-31-47-71t-17-85q0-44 15-78t37-63t48-51t49-46t37-44t15-48q0-39-27-66q-13-13-34-36t-47-51t-54-56t-56-52t-51-37t-43-15q-38 0-65 27l-85 85q-37 37-64 76t-40 87t-14 120q0 112 36 231t101 238t153 234t192 219t219 190t234 150t236 99t226 36"
                />
              </svg>
            }
          />

          <button
            type="submit"
            disabled={isLoading}
            className="block w-full rounded-lg bg-gray-200 hover:bg-gray-300 text-gray-800 transition duration-300 px-5 py-3 text-sm font-medium"
          >
            {isLoading ? "Registering..." : "Sign Up"}
          </button>

          <p className="text-center text-sm mt-5 text-gray-200">
            Already have an account?
            <Link to="/login" className="underline ml-1">
              Sign in
            </Link>
          </p>
        </form>
      </div>
    </div>
  );
};

export default Index;
