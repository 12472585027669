import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"; // Get the category from the URL
import { fetchEventsByCategory } from "../store/eventsSlice";
import HeroSection from "./HeroSection";
import EventCard from "./EventCard";

const EventsList = () => {
  const { category } = useParams(); // Get category from URL (e.g., "/category/concerts")
  const dispatch = useDispatch();
  const { events, loading, error } = useSelector((state) => state.events);
  const [isOpen, setIsOpen] = useState(false);
  const [visibleCards, setVisibleCards] = useState(4); // Initially show 4 cards
  const [sortMethod, setSortMethod] = useState("default"); // State to track sorting method

  const showMoreCards = () => {
    setVisibleCards((prevCount) => prevCount + 4); // Increase the visible cards by 4 each time
  };

  // useEffect(() => {
  //   console.log("blbl?", events);
  // }, [events]);

  // Fetch events for the selected category
  useEffect(() => {
    if (category) {
      dispatch(fetchEventsByCategory(category));
    }
  }, [category, dispatch]);

  // Sort events based on selected sort method
  const sortedEvents = [...events].sort((a, b) => {
    const now = new Date();

    if (sortMethod === "closest") {
      return (
        Math.abs(new Date(a.date) - now) - Math.abs(new Date(b.date) - now)
      );
    } else if (sortMethod === "farthest") {
      return (
        Math.abs(new Date(b.date) - now) - Math.abs(new Date(a.date) - now)
      );
    }
    return events.indexOf(b) - events.indexOf(a); // Default sort (recently added events)
  });

  if (loading)
    return (
      <div className="border-gray-300 mx-auto my-10 h-20 w-20 animate-spin rounded-full border-8 border-t-gray-600"></div>
    );
  if (error) return <div>Error: {error}</div>;
  const categoryData = {
    Concerts: {
      image:
        "https://images.pexels.com/photos/1677710/pexels-photo-1677710.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      title: "Checkout The Best Concerts Near You",
      subtitle: "Find and book tickets for the hottest concerts in your area",
    },
    Sports: {
      image:
        "https://images.pexels.com/photos/269948/pexels-photo-269948.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      title: "Exciting Sports Events Await",
      subtitle: "Catch all the action of your favorite sports live!",
    },
    Theater: {
      image:
        "https://images.pexels.com/photos/713149/pexels-photo-713149.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      title: "Amazing Theater Performances",
      subtitle: "Experience the magic of live theater!",
    },
    Comedy: {
      image:
        "https://images.pexels.com/photos/7234318/pexels-photo-7234318.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      title: "Great Entertaining Experiences",
      subtitle: "Experience the benefits of comedy!",
    },
    Educational: {
      image:
        "https://images.pexels.com/photos/7648306/pexels-photo-7648306.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1",
      title: "Constructive Educational Workshops",
      subtitle: "Experience the magic of knowledge!",
    },
  };
  const heroContent = categoryData[category] || {
    image: "https://images.pexels.com/photos/placeholder.jpg", // default image
    title: "Welcome to Our Events",
    subtitle: "Explore various events happening near you",
  };

  return (
    <div className="min-h-screen">
      <HeroSection
        image={heroContent.image}
        title={heroContent.title}
        subtitle={heroContent.subtitle}
      />
      

      {!loading && !error && events.length === 0 && (
        <div className="text-center mt-10">
          <h2 className="text-2xl font-semibold text-gray-600">
            No Events Found
          </h2>
          <p className="text-gray-500">
            Please check back later for more events.
          </p>
        </div>
      )}
    {events.length > 0 && (
      <div className="flex flex-wrap items-center justify-center gap-2">
        {/* Sorting button */}
        <div className="relative mt-5">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="px-4 py-2 text-sm font-medium bg-white text-gray-700 hover:bg-gray-50 flex items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="1.5em"
              height="1.5em"
              viewBox="0 0 25 25"
            >
              <path
                fill="#571552"
                d="M6.293 4.293a1 1 0 0 1 1.414 0l4 4a1 1 0 0 1-1.414 1.414L8 7.414V19a1 1 0 1 1-2 0V7.414L3.707 9.707a1 1 0 0 1-1.414-1.414zM16 16.586V5a1 1 0 1 1 2 0v11.586l2.293-2.293a1 1 0 0 1 1.414 1.414l-4 4a1 1 0 0 1-1.414 0l-4-4a1 1 0 0 1 1.414-1.414z"
              />
            </svg>
            Sort Events
            <svg
              className={`ml-2 h-5 w-5 text-gray-400 transition-transform ${
                isOpen ? "transform rotate-180" : ""
              }`}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
          {isOpen && (
            <div className="absolute left-0 mt-2 w-56 z-50 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
              <div
                className="py-1"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="options-menu"
              >
                <button
                  onClick={() => setSortMethod("default")}
                  href="#"
                  className="block px-4 py-2 w-full text-start text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Default
                </button>
                <button
                  onClick={() => setSortMethod("closest")}
                  href="#"
                  className="block px-4 py-2 w-full text-start text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Date (Closest to Farthest)
                </button>
                <button
                  onClick={() => setSortMethod("farthest")}
                  href="#"
                  className="block px-4 py-2 w-full text-start text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                  role="menuitem"
                >
                  Date (Farthest to Closest)
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    )}
      <div className="mx-12 p-9 flex justify-center flex-wrap gap-5">
        {sortedEvents.slice(0, visibleCards).map((event) => {
          return <EventCard key={event.id} event={event} />;
        })}
      </div>
      {/* Show button only if there are more cards to display */}
      {visibleCards < events.length && (
        <div className="flex justify-center my-5">
          <button
            onClick={showMoreCards}
            className="py-2 px-4 border border-gray-800 rounded-md bg-gray-200 text-gray-800 font-semibold text-sm hover:bg-gray-300"
          >
            View more
          </button>
        </div>
      )}
    </div>
  );
};

export default EventsList;
