import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/images/tikita.png";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/authSlice";
import { fetchEventsByCategory } from "../store/eventsSlice";
const Navbar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [activeLink, setActiveLink] = useState("/");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleCategoryClick = (category) => {
    dispatch(fetchEventsByCategory(category)); // Dispatch the action when a category is clicked
  };
  // Access user and isLoggedIn from Redux state
  const user = useSelector((state) => state.auth.user); // Adjust the state path to match your Redux store structure
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const dispatch = useDispatch();

  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen);
  const handleSetActive = (link) => () => {
    setActiveLink(link);
  };

  const navLinks = [
    // { name: "All Events", href: "/" },
    { name: "Concerts", href: "/category/Concerts" },
    { name: "Theater", href: "/category/Theater" },
    { name: "Comedy", href: "/category/Comedy" },
    { name: "Sports", href: "/category/Sports" },
    { name: "Educational", href: "/category/Educational" },
  ];

  const linkClasses = (href) =>
    `transition hover:text-gray-500/75 ${
      activeLink === href ? "text-gray-500" : "text-gray-200"
    }`;

  const handleLogout = () => {
    dispatch(logout()); // Call the logout action
    navigate("/"); // Redirect to the home page
  };
  return (
    <header className="bg-gray-900">
      <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8 flex h-16 items-center justify-between">
        <Link to="/">
          <img src={logo} alt="Logo" className="h-12 hide-on-small" />
        </Link>

        <nav className="hidden md:flex gap-6 text-sm" aria-label="Global">
          <Link
            className={linkClasses("/")}
            to="/"
            onClick={() => {
              handleSetActive("/");
              setActiveLink("/");
            }}
          >
            All events
          </Link>
          {navLinks.map((link) => (
            <Link
              key={link.href}
              to={link.href}
              className={linkClasses(link.href)}
              onClick={() => {
                handleSetActive(link.href)();
                setActiveLink(link.href);
                handleCategoryClick(link?.name); // Dispatch action to fetch events
              }}
            >
              {link?.name}
            </Link>
          ))}
        </nav>
        <div className="flex justify-center items-center gap-2 md:hidden">
          {/* Logout Button if user is logged in */}
          {isLoggedIn ? (
            <div className="text-gray-200">
              <span>
                Welcome, <strong>{user.name}</strong>
              </span>
            </div>
          ) : (
            // <button
            //   onClick={() => {
            //     handleLogout();
            //     toggleMobileMenu(); // Close mobile menu after logout
            //   }}
            //   className="rounded-md text-center border border-gray-400 px-7 py-2.5 text-sm font-medium text-gray-200 hover:bg-gray-200 hover:text-gray-800 transition duration-300"
            // >
            //   Logout
            // </button>
            <Link
              to="/login"
              onClick={toggleMobileMenu} // Close mobile menu after navigating to login
              className="rounded-md text-center border border-gray-400 px-7 py-2.5 text-sm font-medium text-gray-200 hover:bg-gray-200 hover:text-gray-800 transition duration-300"
            >
              Login
            </Link>
          )}
          <button
            className="md:hidden rounded p-2 text-gray-100 transition relative"
            onClick={toggleMobileMenu}
          >
            <div className="relative h-8 w-8">
              {/* Hamburger Icon */}
              <span
                className={`absolute inset-0 transform transition-all duration-300 ease-in-out ${
                  isMobileMenuOpen
                    ? "rotate-45 opacity-0"
                    : "rotate-0 opacity-100"
                }`}
              >
                <svg
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M4 6h16M4 12h16M4 18h16"
                  />
                </svg>
              </span>

              {/* "X" Icon */}
              <span
                className={`absolute inset-0 transform transition-all duration-500 ease-in-out ${
                  isMobileMenuOpen
                    ? "rotate-0 opacity-100"
                    : "rotate-45 opacity-0"
                }`}
              >
                <svg
                  className="h-8 w-8"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </span>
            </div>
          </button>
        </div>

        <div className="hidden md:flex items-center gap-4">
          {isLoggedIn && user ? (
            <div className="relative text-md  text-gray-200">
              <div className=" ">
                <div className="w-64 shadow   ">
                  <div
                    onClick={() => setOpen(!open)}
                    className={`relative border-b-4 border-transparent py-3 ${
                      open
                        ? "border-gray-700 transform transition duration-300"
                        : ""
                    }`}
                  >
                    <div className="flex justify-center items-center space-x-3 cursor-pointer">
                      <div className=" text-white text-md">
                        <div className="cursor-pointer flex underline underline-offset-2">
                          <span>Welcome&nbsp;,&nbsp;</span>
                          <span className="font-bold">{user?.name} </span>
                        </div>
                      </div>
                    </div>
                    {open && (
                      <div className="absolute w-60 z-50 px-5 py-3 dark:bg-gray-800 bg-white rounded-lg shadow border dark:border-transparent mt-5 transition-transform ease-out duration-100">
                        <ul className="space-y-3 dark:text-white">
                          {/* <li className="font-medium">
                            <a
                              href="#"
                              className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-gray-700"
                            >
                              <div className="mr-3">
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                  />
                                </svg>
                              </div>
                              Profile
                            </a>
                          </li> */}
                          {user.role === "admin" ? (
                            <li className="font-medium">
                              <Link
                                to="/dashboard"
                                className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-gray-700"
                              >
                                <div className="mr-3">
                                  <svg
                                    className="w-6 h-6"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill="white"
                                      d="M13.5 9V4H20v5zM4 12V4h6.5v8zm9.5 8v-8H20v8zM4 20v-5h6.5v5zm1-9h4.5V5H5zm9.5 8H19v-6h-4.5zm0-11H19V5h-4.5zM5 19h4.5v-3H5zm4.5-3"
                                    />
                                  </svg>
                                </div>
                                Dashboard
                              </Link>
                            </li>
                          ) : null}
                          {/* <hr className="dark:border-gray-700" /> */}
                          <li className="font-medium">
                            <button
                              onClick={handleLogout}
                              className="flex items-center transform transition-colors duration-200 border-r-4 border-transparent hover:border-red-600 pr-24"
                            >
                              <div className="mr-3 text-red-600">
                                <svg
                                  className="w-6 h-6"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                  />
                                </svg>
                              </div>
                              Logout
                            </button>
                          </li>
                        </ul>
                      </div>
                    )}
                  </div>

                  {/* <button className="ml-3 bg-gray-600 hover:bg-gray-500 rounded-full p-2"><svg xmlns="http://www.w3.org/2000/svg" width="1.5em" height="1.5em" viewBox="0 0 24 24"><path fill="white" d="M17 18a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2M1 2h3.27l.94 2H20a1 1 0 0 1 1 1c0 .17-.05.34-.12.5l-3.58 6.47c-.34.61-1 1.03-1.75 1.03H8.1l-.9 1.63l-.03.12a.25.25 0 0 0 .25.25H19v2H7a2 2 0 0 1-2-2c0-.35.09-.68.24-.96l1.36-2.45L3 4H1zm6 16a2 2 0 0 1 2 2a2 2 0 0 1-2 2a2 2 0 0 1-2-2c0-1.11.89-2 2-2m9-7l2.78-5H6.14l2.36 5z"/></svg></button> */}
                </div>
              </div>
            </div>
          ) : (
            <Link
              to="/login"
              className="rounded-md border border-gray-400 px-7 py-2.5 text-sm font-medium text-gray-200 hover:bg-gray-200 hover:text-gray-800 transition duration-300"
            >
              Login
            </Link>
          )}
        </div>
      </div>

      {/* Mobile menu */}
      {isMobileMenuOpen && (
        <div className="bg-gray-900 px-4 py-4 md:hidden space-y-2">
          {/* Show welcome message if user is logged in */}
          {isLoggedIn && user && (
            <></>
            // <div className="text-gray-200 text-center mb-4">
            //   <span>
            //     Welcome, <strong>{user.name}</strong>
            //   </span>
            // </div>
          )}

          {/* Admin-only Dashboard Link */}
          {isLoggedIn && user?.role === "admin" && (
            <Link
              to="/dashboard"
              className="rounded-md block text-center px-7 py-2.5 text-sm font-medium bg-gray-200 hover:bg-gray-300 text-gray-800"
              onClick={toggleMobileMenu}
            >
              Dashboard
            </Link>
          )}
          <Link
            className="flex justify-center text-left text-gray-200 transition hover:text-gray-500/75 py-2 "
            to="/"
            onClick={() => {
              handleSetActive("/");
              setActiveLink("/");
              toggleMobileMenu();
            }}
          >
            All events
          </Link>

          {/* Navigation Links */}
          {navLinks.map((link) => (
            <Link
              key={link.href}
              to={link.href}
              className="flex justify-center text-left text-gray-200 transition hover:text-gray-500/75 py-2 "
              onClick={toggleMobileMenu}
            >
              {link.name}
            </Link>
          ))}
          {isLoggedIn && user ? (
                        <div className="flex items-center justify-center text-white">
                        <button onClick={handleLogout} className="flex items-center">
                          <div className="mr-2 text-red-600">
                            <svg
                              className="w-6 h-6"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                              />
                            </svg>
                          </div>
                          Logout
                        </button>
                      </div>
          
          ) : (<></>
          )}
        </div>
      )}
    </header>
  );
};

export default Navbar;
