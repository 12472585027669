import React from "react";

const Toast = ({ icon, message, onclick, visible, fade }) => {
   // Function to refresh the page
   const handleRefresh = () => {
    window.location.reload(); // Refresh the page
  };
    return (
        <div
        id="toast-success"
        className={`z-50 flex fixed right-1/3 top-24 items-center w-full max-w-sm p-4 mb-4 text-gray-500 bg-green-100 rounded-lg shadow-lg transition-opacity duration-500 ${fade ? 'opacity-0' : 'opacity-100'}`}
        role="alert"
        style={{ display: visible ? 'flex' : 'none' }} // Show or hide the toast
      >
        <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-green-500 rounded-lg">
          {icon}
          <span className="sr-only">Check icon</span>
        </div>
        <div className="ml-3 text-sm font-normal flex-1">{message}</div>      
        
        <button 
          onClick={handleRefresh} // Call the refresh function
          className="text-sm font-medium text-blue-600 hover:underline ml-3 p-1.5 rounded-lg"
        >
          Refresh
        </button>
        
        <button
          type="button"
          className="ms-5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8"
          aria-label="Close"
          onClick={onclick} // Ensure you pass the close handler
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
        </button>
      </div>
  );
};

export default Toast;
