import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { createTicket } from "../store/ticketsSlice";
// import Toast from "./dashboard/Toast";

export default function EventDetails({ onClose, event }) {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { error } = useSelector((state) => state.tickets); // Get tickets state from Redux
  const [toastVisible, setToastVisible] = useState(false);
  // const [toastMessage, setToastMessage] = useState("");
  // const [fadeToast, setFadeToast] = useState(false);
  const [loading, setLoading] = useState(false); // Added state for loading

  const navigate = useNavigate();
  const [formData, setFormData] = useState(event);

  const [countdown, setCountdown] = useState({
    days: 0,
    hours: 0,
    min: 0,
    sec: 0,
  });

  useEffect(() => {
    // Function to calculate the countdown
    const calculateCountdown = () => {
      const eventDate = new Date(formData.date).getTime();
      const now = new Date().getTime();
      const distance = eventDate - now;

      if (distance > 0) {
        const days = Math.floor(distance / (1000 * 60 * 60 * 24));
        const hours = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((distance % (1000 * 60)) / 1000);

        setCountdown({ days, hours, min: minutes, sec: seconds });
      } else {
        clearInterval(timer); // Stop countdown when time is up
      }
    };

    // Run the countdown function every second
    const timer = setInterval(calculateCountdown, 1000);

    // Clean up the interval on component unmount
    return () => clearInterval(timer);
  }, [formData.date]);

  useEffect(() => {
    setFormData(event);
    console.log("form data events details: ", event);
  }, [event]);

  // Function to format date and time
  const formatDateTime = (isoDate) => {
    const eventDate = new Date(isoDate);
    const date = eventDate.toLocaleDateString("fr-FR");
    const time = eventDate.toLocaleTimeString("fr-FR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return { date, time };
  };

  // Extract formatted date and time
  const { date: formattedDate, time } = formatDateTime(formData.date);

  // Handle Get Tickets button click
  const handleGetTickets = () => {
    setLoading(true); // Set loading to true

    const ticketData = {
      eventId: formData._id, // Using formData instead of destructured values
      title: formData.title,
      date: formData.date,
      location: formData.location,
    };
    // console.log("blbl",{event})

    console.log("Ticket data:", ticketData);
    // Dispatch createTicket thunk with ticket data
    dispatch(createTicket(ticketData))
      .unwrap()
      .then((response) => {
        console.log("Ticket created successfully:", response);
        // setToastMessage("Ticket created and sent to your email!");
        setToastVisible(true);

        // Auto-hide the toast after 5 seconds
        setTimeout(() => {
          // setFadeToast(true);
          setTimeout(() => setToastVisible(false), 500); // Hide after fade-out
        }, 5000);
      })
      .catch((error) => {
        console.error("Error creating ticket:", error);
        // setToastMessage("Error creating ticket. Please try again.");
        setToastVisible(true);
      })
      .finally(() => {
        setLoading(false); // Set loading to false after process
      });
  };

  // Show success toast when ticket is successfully created
  // useEffect(() => {
  //   if (!loading && tickets.length > 0) {
  //     setToastMessage("Ticket created and sent to your email!");
  //     setToastVisible(true);

  //     // Auto-hide the toast after 5 seconds
  //     setTimeout(() => {
  //       setFadeToast(true);
  //       setTimeout(() => setToastVisible(false), 500); // Hide after fade-out
  //     }, 5000);
  //   }
  // }, [loading, tickets]); // Runs when loading or tickets state changes

  // Show error toast when there's an error
  useEffect(() => {
    if (error) {
      // setToastMessage(error); // Show error message
      setToastVisible(true);

      // Auto-hide the toast after 5 seconds
      setTimeout(() => {
        // setFadeToast(true);
        setTimeout(() => setToastVisible(false), 500); // Hide after fade-out
      }, 5000);
    }
  }, [error]); // Runs when the error state changes

  return (
    <div
      onClick={onClose}
      className="flex flex-col fixed inset-0 z-50 items-center justify-center bg-black bg-opacity-50"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white shadow-lg p-5 relative max-h-[90vh] overflow-auto w-3/4"
      >
        <div className="flex-1 rounded-lg m-5 flex flex-col items-center justify-center gap-5">
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-3xl font-bold"
          >
            &times;
          </button>
          <h2 id="event_name" className="text-3xl font-bold">
            {formData.title}
          </h2>
          <div className="w-full mx-auto">
            <div className="flex flex-col md:flex-row gap-6 h-[500px] ">
              <div className="md:w-2/3 ">
                <div className="bg-white p-6 rounded-lg shadow-md border border-gray-100">
                  <div className="relative w-full  mb-6 rounded-lg overflow-hidden">
                    <img
                      src={formData.image ? formData.image.url : ""}
                      alt={formData.title}
                      className="w-full h-full object-cover"
                    />
                  </div>

                  <p id="event_description" className="mb-4">
                    {formData.description}
                  </p>
                  <p id="event_date" className="mb-2">
                    📅 Date : {formattedDate}
                  </p>
                  <p id="event_time" className="mb-2">
                    🕒 Time : {time}
                  </p>
                  <p className="font-semibold">
                    ⚡ Limited places - Book now for an exceptional experience!
                  </p>
                </div>
              </div>

              <div className="md:w-1/3 flex flex-col gap-4">
                <div className="bg-white p-6 rounded-lg shadow-md border border-gray-100">
                  <h2 className="text-xl font-semibold mb-4">Event Details</h2>

                  <div className="flex flex-wrap gap-2 mb-6">
                    {Object.entries(countdown).map(([unit, value]) => (
                      <div
                        key={unit}
                        className="bg-red-500 text-white p-2 rounded text-center"
                      >
                        <div className="text-2xl font-bold">{value}</div>
                        <div className="text-xs uppercase">{unit}</div>
                      </div>
                    ))}
                  </div>

                  <div className="space-y-4">
                    <div className="flex items-center space-x-2">
                      <div className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-gray-500 text-xs">
                        👤
                      </div>
                      <div>
                        <div className="text-sm text-gray-500">
                          Organized by
                        </div>
                        <div id="user_name">
                          {formData.userId.firstname +
                            " " +
                            formData.userId.lastname}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <div className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-gray-500 text-xs">
                        🕒
                      </div>
                      <div>
                        <div className="text-sm text-gray-500">
                          Date and Time
                        </div>
                        <div id="event_date">
                          {formattedDate} at {time}
                        </div>
                      </div>
                    </div>

                    <div className="flex items-center space-x-2">
                      <div className="w-5 h-5 bg-gray-200 rounded-full flex items-center justify-center text-gray-500 text-xs">
                        📍
                      </div>
                      <div>
                        <div className="text-sm text-gray-500">Location</div>
                        <div id="event_location">{formData.location}</div>
                      </div>
                    </div>
                  </div>
                </div>

                {!user || (user && user.role === "user") ? (
                  <div>
                    <button
                      onClick={
                        user && user.role === "user"
                          ? handleGetTickets
                          : () => navigate("/login")
                      }
                      className={`w-full ${
                        loading ? "bg-gray-400" : "bg-red-500"
                      } text-white py-4 rounded-md hover:bg-red-600 transition duration-300 my-5`}
                      disabled={loading}
                    >
                      {loading ? "Loading..." : "Get your ticket"}
                    </button>

                    {/* Popup for Loading, Success, or Error */}
                    {(loading || toastVisible || error) && (
                      <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
                        <div className="bg-white p-8 rounded-lg shadow-lg text-center animate-fadeIn">
                          <div className="flex flex-col items-center space-y-4">
                            {loading ? (
                              <>
                                <div className="w-12 h-12 border-4 border-t-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
                                <p className="text-lg font-semibold text-gray-700">
                                  Processing your ticket...
                                </p>
                                <p className="text-sm text-gray-500">
                                  This might take a few seconds, please wait.
                                </p>
                              </>
                            ) : error ? (
                              <>
                                <svg
                                  className="w-12 h-12 text-red-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m6 4h.01M6 18h.01M3 5a2 2 0 012-2h14a2 2 0 012 2v14a2 2 0 01-2 2H5a2 2 0 01-2-2V5z"
                                  />
                                </svg>
                                <p className="text-lg font-semibold text-gray-700">
                                  Failed to send ticket!
                                </p>
                                <p className="text-sm text-gray-500">
                                  {error}. Please try again.
                                </p>
                                <button
                                  onClick={() => {
                                      setToastVisible(false);
                                      onClose();
                                    // Optionally clear the Redux error state here.
                                    // dispatch(clearError()); // Implement this action if needed in your Redux slice.
                                  }}
                                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                                >
                                  Close
                                </button>
                              </>
                            ) : (
                              <>
                                <svg
                                  className="w-12 h-12 text-green-500"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M5 13l4 4L19 7"
                                  />
                                </svg>
                                <p className="text-lg font-semibold text-gray-700">
                                  Ticket sent successfully!
                                </p>
                                <p className="text-sm text-gray-500">
                                  Please check your email for the details.
                                </p>
                                <button
                                  onClick={() => {
                                    setToastVisible(false);
                                    onClose();
                                  }}
                                  className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                                >
                                  Close
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <Link
                    to="/login"
                    className="w-full bg-gray-500 text-white p-3 mb-5 text-center rounded-md hover:bg-gray-600 transition duration-300"
                  >
                    Sign in to get your ticket
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
