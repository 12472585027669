import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { jwtDecode } from "jwt-decode";
import axiosInstance from "./axiosInstance";

// Token expiry check function
const checkTokenExpiry = (token) => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  console.log("Decoded Token:", decodedToken);
  console.log("Token Expiration:", new Date(decodedToken.exp * 1000)); // Convert to milliseconds
  const currentTime = Date.now() / 1000; // Time in seconds
  return decodedToken.exp > currentTime; // true if token is still valid
};

// Create async thunk for fetching events
export const fetchEvents = createAsyncThunk("events/fetchEvents", async () => {
  
    // const response = await fetch("http://localhost:3001/events");
    // if (!response.ok) {
    //   throw new Error("Failed to fetch events");
    // }
    // return response.json();

    const response = await axiosInstance.get("/events");
    return response.data;
  
});

// Create async thunk for fetching events per category
export const fetchEventsByCategory = createAsyncThunk(
  "events/fetchEventsByCategory",
  async (category) => {
    // const response = await fetch(
    //   `http://localhost:3001/events/category/${category}`
    // );
    // console.log("Fetching events for category " + category + ":" + response);
    // if (!response.ok) {
    //   throw new Error("Failed to fetch events");
    // }
    // return response.json(); // Assuming your API returns JSON data
    const response = await axiosInstance.get(`/events/category/${category}`);
    return response.data;
  }
);

// Thunk to add a new event
// export const addEvent = createAsyncThunk(
//   "events/addEvent",
//   async (newEvent) => {
//     const token = localStorage.getItem("token");
//     console.log("From Slice Add Event", newEvent);

//     // Check if the token is expired
//     if (!checkTokenExpiry(token)) {
//       throw new Error("Token expired. Please log in again.");
//     }
//     console.log("Token:", token); // Log token only here

//     const response = await fetch("http://localhost:3001/events/addevent", {
//       method: "POST",
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: `Bearer ${token}`, // Add Authorization header
//       },
//       credentials: "include",
//       body: JSON.stringify(newEvent),
//     });

//     console.log(response);

//     if (!response.ok) {
//       const errorMessage = await response.json(); // Get the error message from response
//       console.error("Error Response:", errorMessage); // Log the error response
//       throw new Error(`Error: ${response.status} - ${errorMessage.message}`);
//     }

//     return response.json(); // Assuming your API returns the newly added event
//   }
// );

// Thunk to add a new event using axiosInstance
export const addEvent = createAsyncThunk(
  "events/addEvent",
  async (newEvent) => {
    const token = localStorage.getItem("token");
    if (!checkTokenExpiry(token)) {
      throw new Error("Token expired. Please log in again.");
    }
    const response = await axiosInstance.post("/events/addevent", newEvent);
    return response.data;
  }
);

// Example updateEvent action
// export const updateEvent = createAsyncThunk(
//   "events/updateEvent",
//   async (eventData) => {
//     const token = localStorage.getItem("token");
//     // Check if the token is expired
//     console.log("data edited: " + JSON.stringify(eventData));
//     console.log("token", !checkTokenExpiry(token));

//     if (!checkTokenExpiry(token)) {
//       throw new Error("Token expired. Please log in again.");
//     }

//     try {
//       const response = await fetch(
//         `http://localhost:3001/events/${eventData.id}`,
//         {
//           method: "PUT",
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${token}`, // Add Authorization header
//           },
//           credentials: "include",
//           body: JSON.stringify(eventData),
//         }
//       );
//       console.log("response", response);

//       if (!response.ok) {
//         throw new Error("Failed to update event");
//       }
//       return await response.json();
//     } catch (error) {
//       console.log(error);
//     }
//   }
// );

// Update an event using axiosInstance
export const updateEvent = createAsyncThunk(
  "events/updateEvent",
  async (eventData) => {
    const token = localStorage.getItem("token");
    if (!checkTokenExpiry(token)) {
      throw new Error("Token expired. Please log in again.");
    }
    const response = await axiosInstance.put(
      `/events/${eventData.id}`,
      eventData
    );
    return response.data;
  }
);

//Soft Delete event
// export const softDeleteEvent = (eventId) => async (dispatch) => {
//   try {
//     const response = await fetch(`http://localhost:3001/events/${eventId}`, {
//       method: "PATCH",
//       headers: {
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify({ isDeleted: true }), // Mark the event as deleted
//     });

//     if (!response.ok) {
//       throw new Error("Failed to soft delete event");
//     }

//     // Fetch the updated events after deletion
//     dispatch(fetchEvents());
//   } catch (error) {
//     console.error("Failed to delete event:", error);
//   }
// };

// Soft delete event using axiosInstance
export const softDeleteEvent = (eventId) => async (dispatch) => {
  try {
    await axiosInstance.patch(`/events/${eventId}`, { isDeleted: true });
    dispatch(fetchEvents());
  } catch (error) {
    console.error("Failed to delete event:", error);
  }
};

// Create the slice
const eventsSlice = createSlice({
  name: "events",
  initialState: {
    events: [],
    loading: false,
    error: null,
  },
  reducers: {
    updateEvent: (state, action) => {
      const { id, ...updatedData } = action.payload;
      const index = state.events.findIndex((event) => event._id === id);
      if (index !== -1) {
        state.events[index] = { ...state.events[index], ...updatedData };
      }
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Events
      .addCase(fetchEvents.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Fetch Events by category
      .addCase(fetchEventsByCategory.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchEventsByCategory.fulfilled, (state, action) => {
        state.loading = false;
        state.events = action.payload;
      })
      .addCase(fetchEventsByCategory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Add Event
      .addCase(addEvent.pending, (state) => {
        state.loading = true;
      })
      .addCase(addEvent.fulfilled, (state, action) => {
        state.loading = false;
        state.events.push(action.payload?.event); // Add the new event to the state
      })
      .addCase(addEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Update Event
      .addCase(updateEvent.pending, (state) => {
        state.loading = true;
        state.error = null; // Reset error on new request
      })
      .addCase(updateEvent.fulfilled, (state, action) => {
        state.loading = false;
        const index = state.events.findIndex(
          (event) => event._id === action.payload._id
        );
        if (index !== -1) state.events[index] = action.payload;
      })
      .addCase(updateEvent.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message; // Capture the error message
      });
  },
});

export default eventsSlice.reducer;
