import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import EventDetails from "./EventDetails";

const EventCard = ({ event }) => {
  const user = useSelector((state) => state.auth.user); // Adjust the state path to match your Redux store structure
  const formRef = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null); // State to hold the selected event for editing

  // Close the popup when clicking outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (formRef.current && !formRef.current.contains(event.target)) {
        setShowPopup(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]); // Only need formRef as dependency

  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  // Function to format the date
const formatDate = (dateString) => {
  const date = new Date(dateString);

  const day = date.getDate(); // Get day of the month
  const month = date.toLocaleString("fr-FR", { month: "short" }); // Abbreviated month
  const hour = String(date.getHours()).padStart(2, '0'); // Get hours in 24-hour format
  const minute = String(date.getMinutes()).padStart(2, '0'); // Get minutes

  // Return the formatted string: "22 nov. à 22h00"
  return `${day} ${month} at ${hour}:${minute}`;
};


  if (!event) {
    return <div>Loading...</div>; // Show a loading state or placeholder
  }

  const { _id, image, title, location } = event;
  // console.log(`event`, event);



  const handleEventClick = (event) => {
    setSelectedEvent(event); // Set the selected event
    togglePopup(); // Open the popup
  };
  
  return (
    <>
      {/* Modal */}
      {showPopup && (
        <div ref={formRef}>
          <EventDetails onClose={togglePopup} event={selectedEvent}/>
        </div>
      )}
      <div
        onClick={() => handleEventClick(event)}
        key={_id}
        className="flex-none cursor-pointer w-[280px] bg-white rounded-lg overflow-hidden shadow-lg transition-transform duration-300 hover:scale-105"
      >
        <div className="relative h-96">
          <img
            src={
              image
                ? image.url || image
                : "https://images.pexels.com/photos/1540406/pexels-photo-1540406.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            }
            alt={title}
            className="absolute inset-0 w-full h-full object-cover"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black to-transparent"></div>
          <div className="absolute bottom-0 left-0 right-0 p-4 text-white">
            <h3 className="text-xl overflow-hidden font-semibold mb-2 text-ellipsis whitespace-nowrap ">
              {title}
            </h3>
            <div className="flex  items-center text-sm mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
                />
              </svg>
              <span className="font-bold text-md">
                {formatDate(event.date)}
              </span>
            </div>
            <div className="flex items-center text-sm">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                />
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                />
              </svg>
              <span>{location}</span>
            </div>
            {!user || (user && user.role === "user") ? (
              <button
                // onClick={user ? handleGetTickets : () => navigate("/login")} // Use navigate for redirect
                className="w-full mt-4 bg-white rounded-md text-gray-800 font-semibold hover:bg-gray-100 transition duration-300 py-2"
              >
                Get Tickets
              </button>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventCard;
