import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "./axiosInstance";

// Get user and token from local storage if they exist
const storedUser = localStorage.getItem("user");
const storedToken = localStorage.getItem("token");

const initialState = {
  user: storedUser ? JSON.parse(storedUser) : null,
  token: storedToken,
  isLoading: false,
  isLoggedIn: !!storedToken, // Set to true if token exists
  error: null,
  message: null,
};

// Async thunk for register
export const register = createAsyncThunk(
  "auth/register",
  async (
    { firstname, lastname, email, password, phoneNumber },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosInstance.post(
        "/auth/register",
        { firstname, lastname, email, password, phoneNumber }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Async thunk for login
export const login = createAsyncThunk(
  "auth/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(
        "/auth/login",
        { email, password }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Async thunk for logout
export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      // Request backend to clear session/cookies
      await axiosInstance.get("/auth/logout");
      // Dispatch the logout action to reset state and clear local storage
      dispatch(clearState());
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
// Async thunk for refreshing token (Optional if you want refresh logic)
export const refreshToken = createAsyncThunk(
  "auth/refreshToken",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post("/auth/refresh", {
        refreshToken: localStorage.getItem("refreshToken"),
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload; // Set user state with payload
    },
    clearState: (state) => {
      state.user = null;
      state.token = null;
      state.isLoggedIn = false;
      state.error = null;
      state.registrationSuccess = false;
      state.message = null; // Clear message when clearing state
    },
    clearMessage: (state) => {
      state.message = null; // Clear the message
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle register actions
      .addCase(register.pending, (state) => {
        state.isLoading = true;
        state.error = null;
        state.registrationSuccess = false;
      })
      .addCase(register.fulfilled, (state) => {
        state.isLoading = false;
        state.registrationSuccess = true; // Mark registration as successful
        state.message = "Registration successful!";
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
        state.registrationSuccess = false;
        state.message = "Registration failed. " + action.payload.message;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isLoggedIn = true;
        state.user = action.payload.user;
        state.token = action.payload.token;
        state.message = "Login successful!";

        // Store user and token in local storage
        localStorage.setItem("user", JSON.stringify(action.payload.user));
        localStorage.setItem("token", action.payload.token);
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload.message;
        state.message = "Error while logging in!";
      })
      .addCase(logout.pending, (state) => {
        state.isLoading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isLoading = false;
        // Clear the token from cookies
        document.cookie =
          "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
        // Now clear the state
        state.user = null;
        state.token = null;
        state.isLoggedIn = false;
        state.error = null;
        localStorage.removeItem("user");
        localStorage.removeItem("token");
      })
      .addCase(logout.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export const { setUser, clearState, clearMessage } = authSlice.actions;
export default authSlice.reducer;
