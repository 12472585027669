import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { updateEvent } from "../store/eventsSlice"; // Ensure you have an updateEvent action

const EditEvent = ({ event, onClose, onUpdateSuccess }) => {
  const [image, setImage] = useState();
  const [uploadError, setUploadError] = useState(null);

  const dispatch = useDispatch();

  const [formData, setFormData] = useState(event);
  const [imagePreview, setImagePreview] = useState(event.image?.url || ""); // Store the image preview URL

  // console.log(formData);

  useEffect(() => {
    if (event) {
      // Update formData when event prop changes
      setFormData({
        ...event,
        date: event.date
          ? new Date(event.date).toISOString().split("T")[0]
          : "", // Format date for input
        time: event.date
          ? new Date(event.date).toTimeString().split(" ")[0].substring(0, 5)
          : "", // Format time for input
      });
      setImagePreview(event.image?.url || ""); // Set initial image preview
    }
  }, [event]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setImage(file);

    // Create a local URL for the uploaded image
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setImagePreview(previewUrl);
    }
  };

  const uploadImageToCloudinary = async (file) => {
    const data = new FormData();
    data.append("file", file);
    data.append(
      "upload_preset",
      process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
    );
    data.append("cloud_name", process.env.REACT_APP_CLOUDINARY_CLOUD_NAME);

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_CLOUD_NAME}/image/upload`,
        {
          method: "POST",
          body: data,
        }
      );
      const result = await response.json();
      return result;
    } catch (error) {
      console.log("There was an error uploading the image " + error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // let updatedFormData = { ...formData };
    let imageUrl = formData.image?.url; // Retain existing image if no new image is uploaded

    if (image) {
      const res = await uploadImageToCloudinary(image);
      // updatedFormData.image = { url: res?.url, public_id: res?.public_id }; // Use optional chaining
      if (!res.url) return setUploadError("Couldn't upload image!");
      imageUrl = res.url; // Update the image URL with the new uploaded image
      setImagePreview(imageUrl); // Update the preview with the uploaded image URL
    }
    // Log the updated image URL and form data
    console.log("Updated Image URL:", imageUrl);
    console.log("Updated Form Data:", {
      ...formData,
      date: new Date(`${formData.date}T${formData.time}`), // Combine date and time

      image: { url: imageUrl },
      id: event._id,
    });
 // Ensure that the time is updated in formData
 console.log("Updated Time:", formData.time);

    // Update event with new data and image URL
    dispatch(
      updateEvent({ ...formData,date: new Date(`${formData.date}T${formData.time}`), image: { url: imageUrl }, id: event._id })
    );
    onUpdateSuccess();
    onClose(); // Close the modal after updating
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
    console.log(`Updated ${name}: ${value}`); // Add this line to debug

  };

  // useEffect(() => {
  //   console.log(image);
  // }, [image]);
  return (
    <div>
      <div
        onClick={onClose}
        className="flex flex-col fixed inset-0 z-50 items-center justify-center bg-black bg-opacity-5"
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="bg-white shadow-lg p-5 relative max-h-[90vh] overflow-auto w-1/2"
        >
          <div className="flex-1 rounded-lg m-5 flex flex-col items-center justify-center gap-5 p-5">
            <button
              onClick={onClose}
              className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 text-3xl font-bold"
            >
              &times;
            </button>
            <h1 className="font-bold text-2xl text-primary mb-5">
              Update Event
            </h1>
            <form onSubmit={handleSubmit} className="w-full mx-auto">
              <div className="grid md:grid-cols-2 md:gap-6">
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="date"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="date"
                    value={formData.date}
                    onChange={handleChange}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="date"
                    className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Date
                  </label>
                </div>
                <div className="relative z-0 w-full mb-5 group">
                  <input
                    type="time"
                    name="time"
                    value={formData.time}
                    onChange={handleChange}
                    className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                    placeholder=" "
                    required
                  />
                  <label
                    for="time"
                    className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                  >
                    Time
                  </label>
                </div>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="title"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Title
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="location"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Location
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="text"
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="category"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Category
                </label>
              </div>

              <div className="relative z-0 w-full mb-5 group">
                <textarea
                  rows="3"
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=" "
                  required
                />
                <label
                  for="description"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Description
                </label>
              </div>
              <div className="relative z-0 w-full mb-5 group">
                <input
                  type="file"
                  name="image"
                  // value={image?.name}
                  accept="image/*"
                  onChange={handleImageUpload}
                  // required
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none  focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                />
                <label
                  for="image"
                  className="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-blue-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Image
                </label>

                {uploadError && (
                  <p className="text-red-600 mt-2">{uploadError}</p>
                )}

                {/* Image Preview */}
                {imagePreview && (
                  <div className="mb-5">
                    <img
                      src={imagePreview}
                      alt="Preview"
                      className="w-full h-auto rounded shadow-md"
                    />
                  </div>
                )}
              </div>
              <div className="flex justify-end items-center gap-5">
                <button
                  onClick={onClose}
                  className="text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                >
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditEvent;
